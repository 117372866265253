import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Arrow from 'react-arrow'

const crmmedia = ({data}) => (
    <Layout>
        <SEO title="CRM Media Redesign" />
        <div className="Hero">
            <div className="HeroImage">
                <Img fluid={data.mediaHero.childImageSharp.fluid} alt="CRM Media Hero Image" />
             </div>
        </div>
        <div className="PageContent">
            <h1>CRM Media Redesign</h1>
            <h4>Time: 2018</h4>

            <h2>Background</h2>
            <p>Veeva CRM Suite is a multi-platform customers relationship management platform tailored for global life science industry. I was part of Veeva CRM's product design team working on CRM iPad app redesign from early 2017 to mid 2018. I worked on Media Redesign after the <Link to={'/crmaccounts'}>Accounts Redesign</Link>.</p>
            <p>Media is a core feature inside Veeva CRM app. It is an open platform for customers to tailor various types of digital sales materials, in order to streamline sales reps’ workflow. It allows customers to manage compliant presentations and distribute the latest content. While end users (sales reps) visiting healthcare providers by showing them Media presentations, sales calls are automatically captured in CRM app, which eliminates administrative burden and simplifies sales reps day-to-day job.</p>

            <h2>Challenges</h2>
            <ul className="list">
                <li><strong>Legacy</strong> -It's challenging to overthrow the heritage. In fact, sunsetting any old features was extremely difficult even if only one or two customers are using it. How might we keep the same functionalities but create a new framework with better user experience?</li>
                <li><strong>It's a complicated system</strong> - Veeva CRM is a highly configurable product that contains regulatory requirements and compliances. Any new UI pattern needs to consider all business flows and use cases.</li>
                <li><strong>Opposite user needs</strong> - Part of the redesign work also included adding new functionality to enable users organize Media content. Through the process I learned there were two typical user types and their needs were drastically different. </li>
                
            </ul>
            <h2>Goal</h2>
            <p>Help users <strong>better organize</strong> their Media content and<strong> find the right content quickly</strong>  on the job.</p>
            <div className="mediaColumns">
                <div>
                    <h3><span role="img" aria-label="Confused Face">😕</span> Media original app problems </h3>
                    <ul className="list">
                        <li>The presentation directory (admin-defined folders) is confusing.</li>
                        <li>UI controls are not well-organized.</li>
                        <li>There is no way users can manage presentations.</li>
                        <li>Search experience is compromised. </li>
                        <li>Users want to view more data besides thumbnails.</li>
                    </ul>
                    
                </div>
                <div>
                    <h3><span role="img" aria-label="Smiling Face with Smiling Eyes">😊</span> Proposed feature enhancements </h3>
                    <ul className="list">
                        <li>Visual folders or labels for more understandable directory.</li>
                        <li>User created folders/labels for personal content management.</li>
                        <li>Add Favorites & Recents views. </li>
                        <li>Enable List view with more data displayed.</li>
                        <li>Improve search.</li>
                    </ul>
                    
                </div>
            </div>

            <h2>Early Explorations & Prototype</h2>
            <div className="ImageWrapper">
                <Img fluid={data.mediaExploration.childImageSharp.fluid} alt="CRM Media Exploration" />
            </div>
            <div className="ImageWrapper">
                <Img fluid={data.mediaPrototype.childImageSharp.fluid} alt="CRM Media Prototype Mock" />
            </div>
            <p>From early research I learned people understand the folder metaphor much better than tags and labels in terms of organizing content, so I went with folder concept and created the first InVision prototype for user testing. </p>

            <h2>Two Different User Voices</h2>
            <p>While the general user feedback were positive but we heard concerns. <strong>Our commercial users love old flat presentation organization with big thumbnails</strong> as they rely on the thumbnails to associate with the presentation content. Many of them also have <strong>6 or less presentations </strong>in their app, folders seem unnecessary. <strong>However, medical users like self-created folders</strong> since they have <strong>hundreds of presentations</strong> in their app, being able to customize presentation organization is a big deal. </p>

            <h2>Final design - Label</h2>
            <div className="ImageWrapper">
                <Img fluid={data.mediaFinalLabel.childImageSharp.fluid} alt="CRM Media Final - Label" />
            </div>
            <p>We decided to use label as the final design <strong>based on considerations of representative user percentages and use cases</strong> - being able to view all presentations in a single view with recognizable thumbnails is the number one use case. It's also critical for sales reps to access presentations with as little scroll or tap as possible since they only have 2-3 minutes with doctors on visits. Users are also familiar with labels as it continues the old directory(admin setup "folders") filter, I combined user created labels with admin setup directory into one list with consistent look and feel, user labels are always at the top of list for easier access.</p>

            <h2>Other Area Improvements</h2>
            <div className="ImageWrapper">
                <Img fluid={data.mediaFinalGrid.childImageSharp.fluid} alt="CRM Media Final - Grid View" />
            </div>
            <div className="ImageWrapper">
                <Img fluid={data.mediaFinalList.childImageSharp.fluid} alt="CRM Media Final - List View" />
            </div>
            <div className="ImageWrapper">
                <Img fluid={data.mediaFinalPresentation.childImageSharp.fluid} alt="CRM Media Final - Presentation Detail View" />
            </div>
            <div className="ImageWrapper">
                <Img fluid={data.mediaFinalSearch.childImageSharp.fluid} alt="CRM Media Final - Search" />
            </div>
        </div>

        <div className="BottomNav">
          <div className="leftNav" >
            <Link to="/crmaccounts"><Arrow className="arrow"
            direction="left"
            shaftWidth={2}
            shaftLength={8}
            headWidth={10}
            headLength={8}
            />Previous project
            </Link>
          </div>
          <div className="rightNav">
            <Link to="/myinsights">Next project<Arrow className="arrow"
            direction="right"
            shaftWidth={2}
            shaftLength={8}
            headWidth={10}
            headLength={8}
            />
            </Link>
          </div>
        </div>

    </Layout>

)


export default crmmedia 

export const query = graphql`
    query {
        mediaHero: file(relativePath: {eq: "crm-media-hero.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaExploration: file(relativePath: {eq: "crm-media-explorations.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaPrototype: file(relativePath: {eq: "crm-media-prototype.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaFinalLabel: file(relativePath: {eq: "crm-media-final-label.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaFinalGrid: file(relativePath: {eq: "crm-media-final-grid.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaFinalList: file(relativePath: {eq: "crm-media-final-list.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaFinalPresentation: file(relativePath: {eq: "crm-media-final-presentation.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }
          mediaFinalSearch: file(relativePath: {eq: "crm-media-final-search.png"}) {
            childImageSharp {
              fluid(maxWidth: 1112) {
               ...GatsbyImageSharpFluid
              }
            }
          }

    }

`